import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import ReactHtmlParser from "react-html-parser"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { SectionTextMain } from "../../styles/common.styles"
import { CommunityInitiativesMain } from "../../styles/community-initiatives.style"

export const CommunityInitiatives = ({
  hexagonData: { name, sectionNum, },
  img,
  title,
  text,
  imageCaption
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <CommunityInitiativesMain className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="flex-wrap">
            <div className="main-col">
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>
              <SectionTitle text={title} variation="small-title" />
              <SectionText text={text} variation="large-text" />
            </div>
            <div className="side-col">
              <div className="second-child">
                <RevealImage alt="Brevan Howard Community" image={img} variation="from-right" />
              </div>

              <SectionTextMain className="img-caption">
                <span>
                  {imageCaption}
                </span>
              </SectionTextMain>
            </div>
          </div>
        </CommunityInitiativesMain>
      }
    </VisibilitySensor >
  )
}
