import React from 'react'
import VisibilitySensor from "react-visibility-sensor"

import { BtnInner } from "../../elements/btn-inner"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { TheBhExperienceMain } from "../../styles/culture/the-bh-experience.styles";
import { seoClassPrefix } from "../../helpers/common"
import { AcfLink } from '../../elements/acf-link'
import { useAgreementState } from '../../context/global-agreement-context'

export const TheBhExperience = ({ hexagonData: { name, sectionNum, }, title, columnTextLeft, columnTextRight, linkData, image1, image1Mobile }) => {
  const { popupTermsStatus } = useAgreementState();
  return (
    <TheBhExperienceMain>
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }} delayedCall={true}>
        {({ isVisible }) =>
          <div className={`top-col to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>
            <SectionTitle text={title} responsive={[{ breakpoint: 640, charLimit: 10}]} />
            <div className="img-wrap desktop-only">
              <RevealImage alt="Brevan Howard Experience" image={image1} variation={'from-left'} />
            </div>
          </div>
        }
      </VisibilitySensor>
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className={`flex-wrap desc-wrap to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
            <div className="text-col-l">
              <SectionText text={columnTextLeft} variation="large-text" delay={200} />
            </div>
            <div className="img-wrap mobile-only">
              <RevealImage alt="Brevan Howard" image={image1Mobile} variation={'from-left'} />
            </div>
            <div className="text-col-r">
              <SectionText text={columnTextRight} variation="large-text" delay={400} />

              <AcfLink linkData={linkData} className={`btn secondary ${seoClassPrefix}culture-the-bh-exp-link bh-link-position`}>
                <BtnInner text={linkData?.linkText} />
              </AcfLink>
            </div>
          </div>
        }
      </VisibilitySensor>
    </TheBhExperienceMain>
  )
}
