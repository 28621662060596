import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { CorporateResponsibilitiesMain } from "../../styles/corporate-responsibility.style"
import { WPTextarea } from "../../helpers/common"

const CrItem = ({ index, text, hwp = 0 }) => {
  return (
    <div className="list-container" style={{ transitionDelay: `${400 + index * 100}ms` }}>
      <div className="cr-list-number">
        {index + hwp + 1}
      </div>
      <div className="cr-list-text">
        <WPTextarea text={text}/>
      </div>
    </div>
  )
}

export const CorporateResponsibilities = ({
  hexagonData: { name, sectionNum, },
  title,
  text,
  list,
  membershipTitle,
  sustainabilityTitle,
  logoList,
  image,
  image2,
}) => {
  const halfWayPoint = Math.ceil(list.length / 2);
  const firstList = list.slice(0, halfWayPoint);
  const secondList = list.slice(halfWayPoint, list.length);

  const classes = ['ilm-box-left', 'ilm-box-center', 'ilm-box-right'];

  return (

    <CorporateResponsibilitiesMain>
      <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
        {({ isVisible }) =>
          <div className={`top-col flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>

            <div className="top-col-img">
              <RevealImage alt="Brevan Howard CSR" image={image2} variation="from-left" />
            </div>

            <div className="top-col-content">
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>

              <SectionTitle text={title} />

              <SectionText text={text} variation="large-text" />
            </div>
          </div>
        }
      </VisibilitySensor>
      <div className="section-separator"></div>

      <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
        {({ isVisible }) =>
          <div className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
            <div className="center-col flex-wrap">
              <SectionTitle text={membershipTitle} variation="smallest-title" responsive={[{ breakpoint: 350, charLimit: 12 }]} />
            </div>
            <div className="flex-wrap">
              {logoList.map(({ text, image }, i) => (
                <div className={`ilm-box ${classes[i % 3]}`} key={i}>
                  <div className="ilm-img-wrap" style={{ transitionDelay: `${300 + i * 100}ms` }}>
                    <img src={image?.localImageFile?.childImageSharp?.original?.src} alt="" />
                  </div>
                  <SectionText text={text} variation="large-text" delay={300 + i * 100} />
                </div>
              ))}
            </div>
          </div>
        }
      </VisibilitySensor>

      <div className="section-separator"></div>

      <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
        {({ isVisible }) =>
          <div className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
            <div className="bottom-col flex-wrap">
              <div className="list-col">
                <SectionTitle text={sustainabilityTitle} variation="smallest-title" />
                <div className="cr-list cr-list-1">
                  {firstList.map(({ text }, index) => (
                    <CrItem key={index} index={index} text={text} />
                  ))}
                </div>
                <div className="cr-list cr-list-2">
                  {secondList.map(({ text }, index) => {
                    if (!text) {
                      return (
                        <React.Fragment key={halfWayPoint + index}></React.Fragment>
                      )
                    }
                    return (
                      <CrItem key={halfWayPoint + index} index={index} text={text} hwp={halfWayPoint} />
                    )
                  })}
                </div>
              </div>
              <div className="side-col">
                <RevealImage alt="Brevan Howard CSR" image={image} variation="from-left" />
              </div>
            </div>
          </div>
        }
      </VisibilitySensor>
    </CorporateResponsibilitiesMain>
  )
}
