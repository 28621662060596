import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { OurTenetsMain } from "../../styles/our-tenets.style"

export const OurTenets = ({
  hexagonData: {
    name,
    sectionNum,
  },
  firstTitle,
  firstText,
  firstImg,
  secondTitle,
  secondText,
  secondImg,
  thirdTitle,
  thirdText,
  thirdImg,
  fourthTitle,
  fourthText,
  fourthImg,
}) => {
  return (
    <OurTenetsMain>
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className="flex-wrap">
            <div className={`main-col t-item-1 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>

              <SectionTitle text={firstTitle} variation="smallest-title" />
              <SectionText text={firstText} />
            </div>

            <div className={`side-col t-item-2 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <SectionTitle text={secondTitle} variation="smallest-title" />
              <SectionText text={secondText} />
            </div>

            <div className={`main-col t-item-3 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <RevealImage alt="Brevan Howard Tenets" image={firstImg} variation="from-left" />
            </div>

            <div className={`side-col t-item-4 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <RevealImage alt="Brevan Howard Tenets" image={secondImg} variation="from-right" />
            </div>
          </div>
        }
      </VisibilitySensor>
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className="flex-wrap">
            <div className={`main-col t-item-5 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <SectionTitle text={thirdTitle} variation="smallest-title" />
              <SectionText text={thirdText} />

              <RevealImage alt="Brevan Howard Tenets" image={thirdImg} variation="from-left desktop-only" />
            </div>

            <div className={`side-col t-item-6 to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <SectionTitle text={fourthTitle} variation="smallest-title" />
              <SectionText text={fourthText} />
              <div className="desktop-only">
                <RevealImage alt="Brevan Howard Tenets" image={fourthImg} variation="from-right" />
              </div>
            </div>

            <div className={`main-col t-item-7 mobile-only to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <RevealImage alt="Brevan Howard Tenets" image={thirdImg} variation="from-left" />
            </div>

            <div className={`side-col t-item-8 mobile-only to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <RevealImage alt="Brevan Howard Tenets" image={fourthImg} variation="from-right" />
            </div>
          </div>
        }
      </VisibilitySensor>

    </OurTenetsMain >
  )
}
